import { Controller } from "@suttyweb/stimulus";

export default class extends Controller {
  static targets = ["preview", "input"];
  static values = {
    defaultSrc: String,
  };

  connect() {
    this.defaultSrcValue = this.previewTarget.src;
  }

  loadFile(event) {
    event?.preventDefault();

    const imageRe = /^image\//;
    const file = [...event.dataTransfer.files].find((f) => imageRe.test(f.type));

    if (!file) return;
    
    let fileSize = file.size;
    if (fileSize > 1048576) {
      fileSize = parseInt(fileSize * 100 / 1048576);
      fileSize = fileSize / 100.0
      const alertMsg = 'The file size should not exceed 1MB. (Current file size: ' + fileSize + ' MB)';
      alert(alertMsg);
      document.getElementById('uploadImage').src = "public/upload.png";
      return;
    }   

    if (this.hasPreviewTarget) {
      this.previewTarget.src = window.URL.createObjectURL(file);
    }

    if (this.hasInputTarget) {
      const dataTransfer = new DataTransfer();

      dataTransfer.items.add(file);

      this.inputTarget.files = dataTransfer.files;
    }
  }

  changePreview(event) {
    if (!this.hasPreviewTarget) return;

    this.previewTarget.src = window.URL.createObjectURL(this.inputTarget.files[0]);
  }

  preventDefaultDrag(event) {
    event.preventDefault();
  }

  reset(event) {
    this.previewTarget.src = this.defaultSrcValue;
  }
}
